
<template>
        <div>
            <div class="row">
                <div v-if="success" class="col-lg-12">
                    <div class="alert alert-success alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <h4><i class="icon fa fa-check"></i> Zákazník byl úspěšně vytvořen!</h4>
                    </div>
                </div>
                <div v-if="errors.length" class="col-lg-12">
                    <div class="alert alert-danger alert-dismissible">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <h4><i class="icon fa fa-ban"></i> Zákazník nemohl být vytvořen!</h4>
                        <p v-for="err in errors" :key="err">{{err}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-9">
                    <div class="box box-widget">
                        <!-- <div class="box-header with-border">
                        <h3 class="box-title">Přidat zákazníka</h3>
                        </div> -->
                        <!-- /.box-header -->
                        <!-- form start -->
                        <div class="box-body">
                            <div class="form-horizontal">
                                <div :class="{'form-group': true, 'has-error': $v.formResponses.company_name.$error}">
                                    <label for="inputName" class="col-sm-2 control-label">Název společnosti / Jméno zákazníka</label>

                                    <div class="col-sm-10">
                                    <input id="inputName" class="form-control"  v-model="$v.formResponses.company_name.$model" type="text">
                                    <span v-if="!$v.formResponses.company_name.required" class="help-block text-yellow">Povinný údaj</span>
                                    <span v-if="!$v.formResponses.company_name.maxLength" class="help-block">Může mít maximálně 200 znaků!</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="box box-widget">
                                <div class="box-header with-border">
                                    <h3 class="box-title"><i class="fa fa-book"></i> Fakturační údaje</h3>
                                    <!-- (<span class="checkbox" style="display: inline;"> -->
                                        <label style="font-weight: normal; margin-left: 10px">
                                        (<input type="checkbox" v-model="sameAsAddress" style="margin-top: 0px; padding-top: 0px">
                                            Adresa stejná jako korespondenční)
                                        </label>
                                    <!-- </span>) -->
                                </div>

                                <div class="box-body">
                                    <div class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.ico.$error}">
                                            <label for="inputICO" class="col-sm-2 control-label">IČO</label>

                                            <div class="col-sm-10">
                                                <input type="text" class="form-control" id="inputICO" v-model="$v.formResponses.ico.$model">
                                                <span v-if="!$v.formResponses.ico.minLength" class="help-block">Musí mít minimálně 7 znaků!</span>
                                                <span v-if="!$v.formResponses.ico.maxLength" class="help-block">Může mít maximálně 9 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.dic.$error}">
                                            <label for="inputDIC" class="col-sm-2 control-label">DIČ</label>

                                            <div class="col-sm-10">
                                                <input type="text" class="form-control" id="inputDIC" v-model="$v.formResponses.dic.$model">
                                                <span v-if="!$v.formResponses.dic.minLength" class="help-block">Musí mít minimálně 8 znaků!</span>
                                                <span v-if="!$v.formResponses.dic.maxLength" class="help-block">Může mít maximálně 12 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!sameAsAddress" class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.invoice_street.$error}">
                                            <label class="col-sm-2 control-label">Ulice</label>

                                            <div class="col-sm-10">
                                                <input class="form-control"  v-model="$v.formResponses.invoice_street.$model" type="text">
                                                <span v-if="!$v.formResponses.invoice_street.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!sameAsAddress" class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.invoice_city.$error}">
                                            <label class="col-sm-2 control-label">Město</label>

                                            <div class="col-sm-10">
                                                <input class="form-control"  v-model="$v.formResponses.invoice_city.$model" type="text">
                                                <span v-if="!$v.formResponses.invoice_city.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                                <span v-if="!$v.formResponses.invoice_city.required" class="help-block">Povinný údaj</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!sameAsAddress" class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.invoice_zip.$error}">
                                            <label for="inputZip" class="col-sm-2 control-label">PSČ</label>

                                            <div class="col-sm-10">
                                                <input id="inputZip" class="form-control"  v-model="$v.formResponses.invoice_zip.$model" type="number">
                                                <span v-if="!$v.formResponses.invoice_zip.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                                <span v-if="!$v.formResponses.invoice_zip.maxLength" class="help-block">Může mít maximálně 6 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!sameAsAddress" class="form-horizontal">
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label">Země</label>

                                            <div class="col-sm-10">
                                                <select v-model="formResponses.invoice_country" class="form-control" style="width: 100%;">
                                                    <option>Česká republika</option>
                                                    <option>Slovensko</option>
                                                    <option>Německo</option>
                                                    <option>Rakousko</option>
                                                    <option>Polsko</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box box-widget">
                                <div class="box-header with-border">
                                <h3 class="box-title"><i class="fa  fa-map-marker"></i> Korespondenční údaje</h3>
                                </div>
                                <!-- /.box-header -->
                                <!-- form start -->
                                <div class="box-body">
                                    <div class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.street.$error}">
                                            <label for="inputStreet" class="col-sm-2 control-label">Ulice</label>

                                            <div class="col-sm-10">
                                                <input id="inputStreet" class="form-control"  v-model="$v.formResponses.street.$model" type="text">
                                                <span v-if="!$v.formResponses.street.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.city.$error}">
                                            <label for="inputCity" class="col-sm-2 control-label">Město</label>

                                            <div class="col-sm-10">
                                                <input id="inputCity" class="form-control"  v-model="$v.formResponses.city.$model" type="text">
                                                <span v-if="!$v.formResponses.city.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                                <span v-if="!$v.formResponses.city.required" class="help-block">Povinný údaj</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-horizontal">
                                        <div :class="{'form-group': true, 'has-error': $v.formResponses.zip.$error}">
                                            <label for="inputZip" class="col-sm-2 control-label">PSČ</label>

                                            <div class="col-sm-10">
                                                <input id="inputZip" class="form-control"  v-model="$v.formResponses.zip.$model" type="number">
                                                <span v-if="!$v.formResponses.zip.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                                <span v-if="!$v.formResponses.zip.maxLength" class="help-block">Může mít maximálně 6 znaků!</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-horizontal">
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label">Země</label>

                                            <div class="col-sm-10">
                                                <select v-model="formResponses.country" class="form-control select2" style="width: 100%;">
                                                    <option selected>Česká republika</option>
                                                    <option>Slovensko</option>
                                                    <option>Německo</option>
                                                    <option>Rakousko</option>
                                                    <option>Polsko</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="box box-widget">
                                <div class="box-header with-border">
                                <h3 class="box-title"><i class="fa fa-users"></i> Osoby</h3>
                                <span v-if="needContactPerson || needInvoicePerson" class="text-yellow pull-right">Chybí<span v-if="needContactPerson"> kontaktní</span><span v-if="needContactPerson && needInvoicePerson"> a</span><span v-if="needInvoicePerson"> fakturační</span> osoba!</span>
                                <span v-if="!maxPeople" class="text-yellow pull-right">Maximální počet osob je 15. </span>
                                </div>
                                <div class="box-body no-padding overflow-auto height-control">
                                    <ul class="nav nav-stacked">
                                        <li v-for="(person, index) in people" :key="index"><a href="#" @click="e => e.preventDefault()">
                                            <div class="row">
                                                <div class="col-xs-10">
                                                    {{person.fullname}} <span v-if="person.email">- {{person.email}}</span> <span v-if="person.phone">- {{person.phone}}</span> <span class="badge bg-light-blue" v-if="person.role.contact">Kontaktní osoba</span> <span class="badge bg-green" v-if="person.role.invoice">Pověřen fakturací</span>
                                                </div>
                                                <div class="col-xs-2">
                                                    <div class="btn-group pull-right">
                                                        <a class="dropdown-toggle" data-toggle="dropdown">
                                                            <span><i class="fa fa-gear"></i></span>
                                                            <span class="sr-only">Toggle Dropdown</span>
                                                        </a>
                                                        <ul class="dropdown-menu" role="menu">
                                                            <li v-if="!person.role.contact"><a href="#" @click="(e) => { e.preventDefault(); makeContactPerson(index) }">Na kontaktní osobu</a></li>
                                                            <li v-if="!person.role.invoice"><a href="#" @click="(e) => { e.preventDefault(); makeInvoicePerson(index) }">Pověřit fakturací</a></li>
                                                            <li><a href="#" @click="(e) => { e.preventDefault(); deletePerson(index) }"><i class="fa fa-trash"></i> Odstranit</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="box-footer">
                                    <div>

                                        <div class="row">
                                            <div class="col-sm-8">
                                                <div class="form-group" :class="{ 'has-error': $v.newPersonResponses.fullname.$error }">
                                                    <input :disabled="!maxPeople" type="text" class="form-control input-sm" placeholder="Jméno a příjmení osoby" v-model="$v.newPersonResponses.fullname.$model">
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-6" style="padding-right: 5px">
                                                        <div class="form-group" :class="{ 'has-error': $v.newPersonResponses.email.$error }">
                                                            <input :disabled="!maxPeople" type="text" class="form-control input-sm" placeholder="Email" v-model="$v.newPersonResponses.email.$model">
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6" style="padding-left: 5px">
                                                        <div class="form-group" :class="{ 'has-error': $v.newPersonResponses.phone.$error }">
                                                            <input :disabled="!maxPeople" type="text" class="form-control input-sm" placeholder="Telefon" v-model="$v.newPersonResponses.phone.$model">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <div class="input-group" style="width: 100%">
                                                        <div class="checkbox">
                                                            <label>
                                                            <input type="checkbox" v-model="newPersonResponses.role.contact">
                                                                Kontaktní osoba
                                                            </label>
                                                        </div>

                                                        <div class="checkbox">
                                                            <label>
                                                            <input type="checkbox" v-model="newPersonResponses.role.invoice">
                                                                Pověřit fakturací
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="form-group" :class="{'has-error': $v.newPersonResponses.$error, 'has-warning': $v.newPersonResponses.$invalid}">
                                                    <div :class="{ 'has-error': $v.newPersonResponses.$error }">
                                                        <span v-if="!$v.newPersonResponses.email.email" class="help-block" style="margin-top: 0px">Musí mít formát emailové adresy (zavináč a za ním adresu).</span>
                                                        <span v-if="!$v.newPersonResponses.phone.phone_format" class="help-block" style="margin-top: 0px">Musí mít formát telefonního čísla (minimálně 9 znaků).</span>
                                                        <span v-if="!$v.newPersonResponses.fullname.maxLength" class="help-block" style="margin-top: 0px">Může mít maximálně 32 znaků!</span>
                                                    </div>
                                                    <div  @mouseenter="$v.newPersonResponses.$touch()">
                                                        <button @click="createPerson()" type="button" class="btn btn-primary btn-sm btn-flat" :disabled="$v.newPersonResponses.$invalid"><i class="fa fa-plus"></i> Přidat osobu</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box box-widget">
                        <div class="box-footer">
                          <div  @mouseenter="$v.formResponses.$touch()">
                              <button type="button" class="btn btn-success pull-left" style="margin-right: 10px" @click="submitForm" :disabled="$v.formResponses.$invalid || needContactPerson || needInvoicePerson">Přidat</button>
                          </div>
                            <div class="checkbox pull-left">
                                <label>
                                    <input v-model="dontClosePage" type="checkbox"> Budu přidávat dál
                                </label>
                            </div>
                            <button type="button" class="btn btn-default pull-right" @click="closeTab(page.id)">Zrušit</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
const name = 'CustomerAdd';

import { mapGetters, mapActions } from "vuex";

// Vuelidate
import { required, minLength, maxLength, email, helpers, requiredIf } from 'vuelidate/lib/validators'

const phone_format = helpers.regex('phone_format', /^[+]?[()/0-9. -]{9,}$/)

export default {
    name,
    data() {
        return {
            page: null,
            dontClosePage: false,

            errors: [],
            success: false,

            people: [],

            sameAsAddress: true,

            newPersonResponses: {
                fullname: '',
                email: '',
                phone: '',
                role: {
                    contact: true,
                    invoice: false,
                }
            },

            formResponses: {
                company_name: '',
                fullname: '',
                ico: '',
                dic: '',
                invoice_street: '',
                invoice_city: '',
                invoice_zip: '',
                invoice_country: '',
                street: '',
                city: '',
                zip: '',
                country: ''
            }
        }
    },
    validations: {
        formResponses: {
            company_name: {
                required,
                maxLength: maxLength(200)
            },
            ico: {
                minLength: minLength(7),
                maxLength: maxLength(9)
            },
            dic: {
                minLength: minLength(8),
                maxLength: maxLength(12)
            },
            invoice_street: {
                maxLength: maxLength(32),
            },
            invoice_city: {
                maxLength: maxLength(32),
                required: requiredIf(function() {
                    return this.formResponses.invoice_street || this.formResponses.invoice_zip;
                })
            },
            invoice_zip: {
                minLength: minLength(5),
                maxLength: maxLength(6)
            },
            invoice_country: {
            },
            street: {
                maxLength: maxLength(32),
            },
            city: {
                maxLength: maxLength(32),
                required: requiredIf(function() {
                    return this.formResponses.street || this.formResponses.zip;
                })
            },
            zip: {
                minLength: minLength(5),
                maxLength: maxLength(6)
            },
            country: {
            }
        },

        newPersonResponses: {
            fullname: {
                required,
                maxLength: maxLength(32)
            },
            email: {
                maxLength: maxLength(32),
                email
            },
            phone: {
                phone_format
            },
        }
    },
    computed: {
        ...mapGetters(['getAllPages']),

        maxPeople(){
            return this.people.length <= 15
        },

        needInvoicePerson(){
            let need = true;
            this.people.forEach((item) => {
                if (item.role.invoice) {
                    need = false;
                }
            });

            if ((!this.formResponses.invoice_street &&
                !this.formResponses.invoice_city &&
                !this.formResponses.invoice_zip &&
                !this.formResponses.invoice_dic) ||

                ((!this.formResponses.street &&
                !this.formResponses.city &&
                !this.formResponses.zip &&
                !this.formResponses.dic) && this.sameAsAddress))

                need = false;

            return need;
        },

        needContactPerson(){
            let need = true;

            this.people.forEach((item) => {
                if (item.role.contact) {
                    need = false;
                }
            });

            return need;
        }
    },
    watch: {
        needInvoicePerson: {
            deep: true,
            handler: function (need) {
                this.newPersonResponses.role.invoice = need;
            }
        },
        needContactPerson: {
            deep: true,
            handler: function (need) {
                this.newPersonResponses.role.contact = need;
            }
        }
    },
    methods: {
        ...mapActions(['makeActiveTab', 'addTab', 'closeTab', 'addCustomer', 'makeCurrentCus']),

        getDate(t){
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}. ${mth}. ${yrs}`;
        },

        async submitForm(e) {
            e.preventDefault();

            if (this.$v.formResponses.$invalid)
                return 0;

            this.errors = [];

            const values = {
                company_name: this.formResponses.company_name,
                ico: this.formResponses.ico,
                dic: this.formResponses.dic,
                invoice_street: this.sameAsAddress ? this.formResponses.street : this.formResponses.invoice_street,
                invoice_city: this.sameAsAddress ? this.formResponses.city : this.formResponses.invoice_city,
                invoice_zip: this.sameAsAddress ? this.formResponses.zip : this.formResponses.invoice_zip,
                invoice_country: this.sameAsAddress ? this.formResponses.country : this.formResponses.invoice_country,
                street: this.formResponses.street,
                city: this.formResponses.city,
                zip: this.formResponses.zip,
                country: this.formResponses.country,
                people: this.people
            }

            const res = await this.addCustomer(values);

            // Handling response
            if(res.existing){
                this.$alerts.message('Záznam již existuje.', "", 'error');
                return false;

            } else if (res.msg == "Success."){
                this.$alerts.toast('Záznam zákazníka úspěšně vytvořen.', 'success');
                if (this.dontClosePage){
                    // Clear data
                    this.formResponses= {
                        company_name: '',
                        fullname: '',
                        ico: '',
                        dic: '',
                        invoice_street: '',
                        invoice_city: '',
                        invoice_zip: '',
                        invoice_country: '',
                        street: '',
                        city: '',
                        zip: '',
                        country: ''
                    };
                    this.people = [];
                    this.$nextTick(() => { this.$v.$reset() })
                }
                else{
                    this.closeTab(this.page.id);
                    this.makeCurrentCus(res.id);
                }
            }
            else
                this.errors.push(res.msg);
        },

        // Methods for hangling people adding

        createPerson(){
            if (this.$v.newPersonResponses.$invalid)
                return null;

            let exit = false;

            this.people.forEach(item => {
                if (item.fullname == this.newPersonResponses.fullname) exit = true;
            });
            if(exit) return null;

            const person = {
                id: this.people.length || 0,
                fullname: this.newPersonResponses.fullname.trim(),
                email: this.newPersonResponses.email.trim(),
                phone: this.newPersonResponses.phone.trim(),
                role: {
                    contact: this.newPersonResponses.role.contact,
                    invoice: this.newPersonResponses.role.invoice
                },
                hover: false
            }

            this.people.push(person);

            if (this.newPersonResponses.role.contact)
                this.makeContactPerson(this.people.length - 1);
            if (this.newPersonResponses.role.invoice)
                this.makeInvoicePerson(this.people.length - 1)

            this.newPersonResponses.fullname = '';
            this.newPersonResponses.email = '';
            this.newPersonResponses.phone = '';

            this.$v.newPersonResponses.$reset();
        },

        deletePerson(index){
            this.people.splice(index, 1);
        },

        makeContactPerson(index){
            this.people.forEach((el, i) => {
                this.people[i].role.contact = false;
            })
            this.people[index].role.contact = true;
        },

        makeInvoicePerson(index){
            this.people.forEach((el, i) => {
                this.people[i].role.invoice = false;
            })
            this.people[index].role.invoice = true;
        },



    },
    async created(){
        this.page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(this.page);
        this.makeActiveTab(this.page);
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
}
/* Forbid arrows in input type number for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Forbid arrows in input type number for Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

a {
    cursor: pointer;
}
</style>